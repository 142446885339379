import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiFilter, BiSearch, BiX, BiEdit, BiTrash } from 'react-icons/bi';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { apiCall } from '../backend/api/apiService';
import { useAuthStore } from '../store/authStore';
import "../styles/warehouses.css";

export default function Warehouses() {
    const [currentPage, setCurrentPage] = useState(1);
    const [warehousesPerPage, setWarehousesPerPage] = useState(10);
    const [warehouses, setWarehouses] = useState([]);
    const [filteredWarehouses, setFilteredWarehouses] = useState([]);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        brand_id: null,
        name: '',
        address: '',
        country: '',
        state: '',
        zipcode: '',
        manager_name: '',
        manager_email: '',
        manager_phone: '',
        status: 'active'
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingWarehouse, setEditingWarehouse] = useState(null);

    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;

    const nextPage = () => {
        if (currentPage < Math.ceil(warehouses.length / warehousesPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getWarehouses = async () => {
        const response = await apiCall(`/api/warehouses/brand/${brand_id}`, 'GET');
        if (response && response.data && Object.values(response.data).length > 0) {
            setWarehouses(Object.values(response.data));
        } else {
            setWarehouses([]);
        }
    };

    const handleEdit = (warehouse) => {
        setEditingWarehouse(warehouse);
        setFormData({
            name: warehouse.name,
            address: warehouse.address,
            country: warehouse.country,
            state: warehouse.state,
            zipcode: warehouse.zipcode,
            manager_name: warehouse.manager_name,
            manager_email: warehouse.manager_email,
            manager_phone: warehouse.manager_phone,
            status: warehouse.status
        });
        setIsEditMode(true);
        setShowModal(true);
    };

    const handleDelete = async (warehouseId) => {
        if (window.confirm('Are you sure you want to delete this warehouse?')) {
            try {
                await apiCall(`/api/warehouses/${warehouseId}`, 'DELETE');
                getWarehouses();
            } catch (error) {
                console.error('Error deleting warehouse:', error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await apiCall(`/api/warehouses/${editingWarehouse.id}`, 'PUT', {
                    ...formData,
                    brand_id
                });
            } else {
                await apiCall('/api/warehouses/create', 'POST', {
                    ...formData,
                    brand_id
                });
            }
            setShowModal(false);
            getWarehouses();
            setFormData({
                name: '',
                address: '',
                country: '',
                state: '',
                zipcode: '',
                manager_name: '',
                manager_email: '',
                manager_phone: '',
                status: 'Draft'
            });
            setIsEditMode(false);
            setEditingWarehouse(null);
        } catch (error) {
            console.error('Error saving warehouse:', error);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setIsEditMode(false);
        setEditingWarehouse(null);
        setFormData({
            name: '',
            address: '',
            country: '',
            state: '',
            zipcode: '',
            manager_name: '',
            manager_email: '',
            manager_phone: '',
            status: 'Draft'
        });
    };

    const handlePhoneChange = (value) => {
        setFormData(prev => ({
            ...prev,
            manager_phone: value || ''
        }));
    };

    useEffect(() => {
        getWarehouses();
    }, []);

    useEffect(() => {
        if (warehouses.length !== 0) {
            const filteredWarehousesTemp = warehouses.filter(warehouse =>
                warehouse.name.toLowerCase().includes(search.toLowerCase())
            );
            const indexOfLastWarehouse = currentPage * warehousesPerPage;
            const indexOfFirstWarehouse = indexOfLastWarehouse - warehousesPerPage;
            const slicedFilters = filteredWarehousesTemp.slice(indexOfFirstWarehouse, indexOfLastWarehouse);
            setFilteredWarehouses(slicedFilters);
        }
    }, [search, warehouses, warehousesPerPage, currentPage]);

    return (
        <div className="warehouses">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1>Warehouses</h1>
                    <div className="shop-search">
                        <BiSearch color='black' size={30} />
                        <input
                            type="text"
                            placeholder="Search For Warehouses"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="warehousesPerPage">Items per page: </label>
                        <select
                            id="warehousesPerPage"
                            onChange={(e) => setWarehousesPerPage(Number(e.target.value))}
                            value={warehousesPerPage}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                    <button className="add-product-button" onClick={() => setShowModal(true)}>
                        Add Warehouse
                    </button>
                </div>
            </div>

            <div className="table-container">
                <table className="product-table">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Warehouse Name</th>
                            <th>Location</th>
                            <th>Manager Name</th>
                            <th>Manager Phone</th>
                            <th>Manager Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredWarehouses.length > 0 ? (
                            filteredWarehouses.map((warehouse, index) => (
                                <tr key={warehouse.id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                    <td>{index + 1}</td>
                                    <td>{warehouse.name}</td>
                                    <td>{`${warehouse.address}, ${warehouse.country}, ${warehouse.state} ${warehouse.zipcode}`}</td>
                                    <td>{warehouse.manager_name}</td>
                                    <td>{warehouse.manager_phone}</td>
                                    <td>{warehouse.manager_email}</td>
                                    <td>{warehouse.status}</td>
                                    <td>
                                        <div className="action-buttons">
                                            <button
                                                className="edit-btn"
                                                onClick={() => handleEdit(warehouse)}
                                                title="Edit warehouse"
                                            >
                                                <BiEdit size={18} />
                                                <span>Edit</span>
                                            </button>
                                            <button
                                                className="delete-btn"
                                                onClick={() => handleDelete(warehouse.id)}
                                                title="Delete warehouse"
                                            >
                                                <BiTrash size={18} />
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" style={{ textAlign: 'center', padding: '20px' }}>
                                    No warehouses found. Click "Add Warehouse" to create one.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="pagination">
                    <span>
                        Showing {currentPage * warehousesPerPage - warehousesPerPage + 1} - {Math.min(currentPage * warehousesPerPage, warehouses.length)} of {warehouses.length} Warehouses
                    </span>
                    <div className="pagination-controls">
                        <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                            <BiChevronLeft size={40} color='black' />
                        </button>
                        <span>{currentPage} / {Math.ceil(warehouses.length / warehousesPerPage)}</span>
                        <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(warehouses.length / warehousesPerPage)}>
                            <BiChevronRight size={40} color='black' />
                        </button>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>{isEditMode ? 'Edit Warehouse' : 'Add New Warehouse'}</h2>
                            <button className="close-button" onClick={handleModalClose}>
                                <BiX size={24} />
                            </button>
                        </div>

                        <form onSubmit={handleSubmit} className="warehouse-form">
                            <div className="form-group">
                                <label htmlFor="name">Warehouse Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder="Enter warehouse name"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    placeholder="Enter complete street address"
                                    value={formData.address}
                                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-row">
                                <div className="form-group" style={{ width: '88%' }}>
                                    <label htmlFor="country">Country</label>
                                    <input
                                        type="text"
                                        id="country"
                                        placeholder="Enter country"
                                        value={formData.country}
                                        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group" style={{ width: '88%' }}>
                                    <label htmlFor="state">State</label>
                                    <input
                                        type="text"
                                        id="state"
                                        placeholder="Enter state"
                                        value={formData.state}
                                        onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group" style={{ width: '88%' }}>
                                    <label htmlFor="zipcode">ZIP Code</label>
                                    <input
                                        type="text"
                                        id="zipcode"
                                        placeholder="Enter ZIP code"
                                        value={formData.zipcode}
                                        onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group" style={{ width: '88%' }}>
                                    <label htmlFor="manager_name">Manager Name</label>
                                    <input
                                        type="text"
                                        id="manager_name"
                                        placeholder="Enter manager's full name"
                                        value={formData.manager_name}
                                        onChange={(e) => setFormData({ ...formData, manager_name: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group" style={{ width: '88%' }}>
                                    <label htmlFor="manager_email">Manager Email</label>
                                    <input
                                        type="email"
                                        id="manager_email"
                                        placeholder="Enter manager's email"
                                        value={formData.manager_email}
                                        onChange={(e) => setFormData({ ...formData, manager_email: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group" style={{ width: '88%' }}>
                                    <label htmlFor="manager_phone">Manager Phone</label>
                                    <PhoneInput
                                        international
                                        defaultCountry="IN"
                                        value={formData.manager_phone}
                                        onChange={handlePhoneChange}
                                        className="phone-input"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="products-per-page" style={{ margin: 0 }}>
                                <label htmlFor="status">Status</label>
                                <select
                                    id="status"
                                    value={formData.status}
                                    onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                                >
                                    <option value="draft">Draft</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>

                            <div className="form-actions">
                                <button type="button" onClick={handleModalClose}>Cancel</button>
                                <button type="submit" style={{ backgroundColor: '#000' }}>
                                    {isEditMode ? 'Update Warehouse' : 'Create Warehouse'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
